import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//begin::Icons
import {ReactComponent as IconWordpress} from "../../icons/services/wordpress.svg"
import {ReactComponent as IconSymfony} from "../../icons/services/symfony.svg";
import {ReactComponent as IconNotif} from "../../assets/icons/messages/mail-notif.svg";
import {ReactComponent as IconMailTracking} from "../../assets/icons/messages/mail-tracking.svg";
import {ReactComponent as IconProfileCircle} from "../../assets/icons/users/profile-circle.svg";
import {ReactComponent as IconTextBlock} from "../../assets/icons/content/text-block.svg";
import {ReactComponent as IconBezier} from "../../assets/icons/design/bezier.svg";

//end::Icons
async function getMessages(setMessages) {
    try {
        // ... votre code pour récupérer les messages ...
    } catch (error) {
        console.error('Une erreur s\'est produite :', error.message);
    }
}

function NavInfos({ messages }) {
    const [visibleIndex, setVisibleIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setVisibleIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 20000);

        return () => clearInterval(intervalId);
    }, [messages.length]);

    if (messages.length > 0) {
        return (
            <div className={"nav-infos"}>
                {messages.map((info, index) => (
                    <p key={index} className={`nav-info ${index === visibleIndex ? 'visible' : 'hidden'}`}>
                        {info}
                    </p>
                ))}
            </div>
        );
    } else {
        return null;
    }
}

function NavItem({title, description, link, icon, handleClose}){
    return (
        <>
            <Link to={link} className="nav-item" onClick={handleClose}>
                <div>
                    {icon}
                </div>
                <div>
                    <p className={'item-title'}>{title}</p>
                    <p className={'item-description'}>{description}</p>
                </div>
            </Link>
        </>
    );
}

export default function Navbar() {
    const [scrolled, setScrolled] = useState(false);
    const [navShow, setNavShow] = useState(false);
    const [submenuShow, setSubmenuShow] = useState(false);
    const [messages, setMessages] = useState([]);

    // useEffect(() => {
    //     async function fetchData() {
    //         await getMessages(setMessages);
    //     }
    //
    //     fetchData();
    // }, []);

    useEffect(() => {
        function handleScroll() {
            setScrolled(window.scrollY > 0);
        }

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const toggleNav = () => {
        setNavShow(!navShow);
    };

    const toggleSubmenu = (e) => {
        e.preventDefault();
        setSubmenuShow(!submenuShow);
    };

    const handleClose = () => {
        setNavShow(false);
        window.scrollTo(0,0);
    }

    return (
        <nav className={`navbar ${scrolled ? "scrolled" : ""} ${navShow ? "nav-show" : ""}`}>
            <NavInfos messages={messages}/>
            <div className={'nav-container'}>
                <div>
                    <div>
                        <div className={'nav-toggle-container'}>
                            <button className="nav-toggle" onClick={toggleNav}>
                                <div></div>
                                <div></div>
                                <div></div>
                            </button>
                        </div>
                        <div className={'nav-logo'}>
                            <Link to={'/'} onClick={handleClose}>
                                <img
                                    src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704554039/identity/lonx2knznlocudkdnr0g.svg"
                                    alt="SEVENTEEN INFORMATIQUE"/>
                            </Link>
                        </div>
                    </div>
                    {/*<div>*/}
                        {/*<ul className={'nav-links-container'}>*/}
                        {/*    <li>*/}
                        {/*        <Link to={'/'}>Accueil</Link>*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*        <Link to={'/realisations'}>Réalisations</Link>*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*        <Link to={'/contact'}>Contact</Link>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}
                    {/*</div>*/}
                    <div>
                        <Link className={'btn btn-primary'} onClick={handleClose} to={'/contact'}>Contactez-nous</Link>
                    </div>
                </div>
            </div>
            <div className={'nav-bigmenu-container'}>
                <div className="bigmenu-header">
                    <div>
                        <img
                            src="https://res.cloudinary.com/seventeen-informatique/image/upload/v1704554039/identity/lonx2knznlocudkdnr0g.svg"
                            alt="SEVENTEEN INFORMATIQUE" height="30px"/>
                    </div>
                    <div>
                        <button id="bigmenu-close" onClick={toggleNav}>
                            <div></div>
                            <div></div>
                        </button>
                    </div>
                </div>
                <div className='bigmenu-col-container'>
                    <div className={'bigmenu-col'}>
                        <div>
                            <h2>Nos Services</h2>
                            <ul>
                                <li>
                                    <NavItem handleClose={handleClose} title="Wordpress" description="Service de création de site Wordpress"
                                             link="/site-wordpress" icon={<IconTextBlock/>}/>
                                </li>
                                <li>
                                <NavItem handleClose={handleClose} title="From Scratch" description="Service de création de site From Scratch"
                                             link="/site-full-code" icon={<IconSymfony/>}/>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>A propos</h2>
                            <ul>
                                <li>
                                    <NavItem handleClose={handleClose} title="Corentin" description="Développeur & CEO" link="/team/csamard" icon={<IconProfileCircle />}/>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Tools</h2>
                            <ul>
                                <li>
                                    <NavItem handleClose={handleClose} title="Password Generator" link="https://pwd-generator.tools.seventeeninfo.fr/" description="Générer des mots de passe robuste gratuitement." icon={<IconTextBlock />} />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bigmenu-col">
                        <div>
                            <h2>Nos réalisations</h2>
                            <ul>
                                <li>
                                    <NavItem handleClose={handleClose} title="Nos réalisations" description="Consulter nos réalisations" link="/realisations" icon={<IconBezier />} />
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Contact</h2>
                            <ul>
                                <li>
                                    <NavItem handleClose={handleClose} title="Contactez-nous" description="Echangeons ensemble sur votre projet"
                                             link="/contact" icon={<IconNotif />}/>
                                </li>
                                <li>
                                    <NavItem handleClose={handleClose} title="Suivre un message"
                                             description="Suivre l'avancer de traitement de votre message"
                                             link="/contact/track" icon={<IconMailTracking />}/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
